/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsCallDistribution } from '../models/hmnz-ts-call-distribution';
import { TsCallDistributionFilters } from '../models/ts-call-distribution-filters';

@Injectable({ providedIn: 'root' })
export class TsCallDistributionResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsCallDistributions()` */
  static readonly GetTsCallDistributionsPath = '/api/resource/TSCallDistribution';

  /**
   * Get TSCallDistribution resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsCallDistributions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsCallDistributions$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsCallDistribution>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallDistributionResourceService.GetTsCallDistributionsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsCallDistribution>;
        }>;
      })
    );
  }

  /**
   * Get TSCallDistribution resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsCallDistributions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsCallDistributions(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsCallDistribution>;
}> {
    return this.getTsCallDistributions$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsCallDistribution>;
}>): {
'data': Array<HmnzTsCallDistribution>;
} => r.body)
    );
  }

  /** Path part for operation `createTsCallDistribution()` */
  static readonly CreateTsCallDistributionPath = '/api/resource/TSCallDistribution';

  /**
   * Create a new TSCallDistribution resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsCallDistribution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallDistribution$Response(
    params: {
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsCallDistribution;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallDistributionResourceService.CreateTsCallDistributionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsCallDistribution;
        }>;
      })
    );
  }

  /**
   * Create a new TSCallDistribution resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsCallDistribution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallDistribution(
    params: {
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsCallDistribution;
}> {
    return this.createTsCallDistribution$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsCallDistribution;
}>): {
'data': HmnzTsCallDistribution;
} => r.body)
    );
  }

  /** Path part for operation `patchTsCallDistribution()` */
  static readonly PatchTsCallDistributionPath = '/api/resource/TSCallDistribution';

  /**
   * Patch TSCallDistribution resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsCallDistribution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallDistribution$Response(
    params: {
      filters?: TsCallDistributionFilters;
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallDistribution>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallDistributionResourceService.PatchTsCallDistributionPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallDistribution>;
      })
    );
  }

  /**
   * Patch TSCallDistribution resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsCallDistribution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallDistribution(
    params: {
      filters?: TsCallDistributionFilters;
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<HmnzTsCallDistribution> {
    return this.patchTsCallDistribution$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallDistribution>): HmnzTsCallDistribution => r.body)
    );
  }

  /** Path part for operation `updateTsCallDistribution()` */
  static readonly UpdateTsCallDistributionPath = '/api/resource/TSCallDistribution/{name}';

  /**
   * Update TSCallDistribution resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsCallDistribution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallDistribution$Response(
    params: {

    /**
     * The name of the TSCallDistribution to update
     */
      name: string;
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallDistribution>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallDistributionResourceService.UpdateTsCallDistributionPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallDistribution>;
      })
    );
  }

  /**
   * Update TSCallDistribution resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsCallDistribution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallDistribution(
    params: {

    /**
     * The name of the TSCallDistribution to update
     */
      name: string;
      body: HmnzTsCallDistribution
    },
    context?: HttpContext
  ): Observable<HmnzTsCallDistribution> {
    return this.updateTsCallDistribution$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallDistribution>): HmnzTsCallDistribution => r.body)
    );
  }

  /** Path part for operation `deleteTsCallDistribution()` */
  static readonly DeleteTsCallDistributionPath = '/api/resource/TSCallDistribution/{name}';

  /**
   * Delete TSCallDistribution resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTsCallDistribution()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTsCallDistribution$Response(
    params: {

    /**
     * The name of the TSCallDistribution to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallDistributionResourceService.DeleteTsCallDistributionPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete TSCallDistribution resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTsCallDistribution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTsCallDistribution(
    params: {

    /**
     * The name of the TSCallDistribution to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteTsCallDistribution$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
