/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesEmployment } from '../models/hmnz-telesales-employment';
import { TelesalesEmploymentFilters } from '../models/telesales-employment-filters';

@Injectable({ providedIn: 'root' })
export class TelesalesEmploymentResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesEmployments()` */
  static readonly GetTelesalesEmploymentsPath = '/api/resource/TelesalesEmployment';

  /**
   * Get TelesalesEmployment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesEmployments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesEmployments$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesEmployment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.GetTelesalesEmploymentsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesEmployment>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesEmployment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesEmployments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesEmployments(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesEmployment>;
}> {
    return this.getTelesalesEmployments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesEmployment>;
}>): {
'data': Array<HmnzTelesalesEmployment>;
} => r.body)
    );
  }

  /** Path part for operation `createTelesalesEmployment()` */
  static readonly CreateTelesalesEmploymentPath = '/api/resource/TelesalesEmployment';

  /**
   * Create a new TelesalesEmployment resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTelesalesEmployment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesEmployment$Response(
    params: {
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTelesalesEmployment;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.CreateTelesalesEmploymentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTelesalesEmployment;
        }>;
      })
    );
  }

  /**
   * Create a new TelesalesEmployment resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTelesalesEmployment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesEmployment(
    params: {
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTelesalesEmployment;
}> {
    return this.createTelesalesEmployment$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTelesalesEmployment;
}>): {
'data': HmnzTelesalesEmployment;
} => r.body)
    );
  }

  /** Path part for operation `patchTelesalesEmployment()` */
  static readonly PatchTelesalesEmploymentPath = '/api/resource/TelesalesEmployment';

  /**
   * Patch TelesalesEmployment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTelesalesEmployment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesEmployment$Response(
    params: {
      filters?: TelesalesEmploymentFilters;
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesEmployment>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.PatchTelesalesEmploymentPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesEmployment>;
      })
    );
  }

  /**
   * Patch TelesalesEmployment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTelesalesEmployment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesEmployment(
    params: {
      filters?: TelesalesEmploymentFilters;
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesEmployment> {
    return this.patchTelesalesEmployment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesEmployment>): HmnzTelesalesEmployment => r.body)
    );
  }

  /** Path part for operation `updateTelesalesEmployment()` */
  static readonly UpdateTelesalesEmploymentPath = '/api/resource/TelesalesEmployment/{name}';

  /**
   * Update TelesalesEmployment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTelesalesEmployment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesEmployment$Response(
    params: {

    /**
     * The name of the TelesalesEmployment to update
     */
      name: string;
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesEmployment>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.UpdateTelesalesEmploymentPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesEmployment>;
      })
    );
  }

  /**
   * Update TelesalesEmployment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTelesalesEmployment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesEmployment(
    params: {

    /**
     * The name of the TelesalesEmployment to update
     */
      name: string;
      body: HmnzTelesalesEmployment
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesEmployment> {
    return this.updateTelesalesEmployment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesEmployment>): HmnzTelesalesEmployment => r.body)
    );
  }

  /** Path part for operation `deleteTelesalesEmployment()` */
  static readonly DeleteTelesalesEmploymentPath = '/api/resource/TelesalesEmployment/{name}';

  /**
   * Delete TelesalesEmployment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTelesalesEmployment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTelesalesEmployment$Response(
    params: {

    /**
     * The name of the TelesalesEmployment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.DeleteTelesalesEmploymentPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete TelesalesEmployment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTelesalesEmployment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTelesalesEmployment(
    params: {

    /**
     * The name of the TelesalesEmployment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteTelesalesEmployment$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
